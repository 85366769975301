import {io} from 'socket.io-client';
import {AuthService} from '@/services/auth';
import {app} from '../main.js'

import {
    SOCKET_BASE_URL,
    CONNECTED,
    SOCKET_ERROR,
    PRIVATE_MESSAGES_RECEIVED,
    ON_SUBSCRIBE_TO_PRINCIPAL_SOCKET_RECEIVED,
    DISCONNECTED,
    ON_SUBSCRIBE_TO_PRINCIPAL_SOCKET_SEND
} from '../.env';


export class PrincipalSocketInstance {


    static socket;

    static connectToIo() {

        this.socket = io(SOCKET_BASE_URL, {
            query: {
                token: localStorage.getItem('accessToken')
            },
            reconnectionDelay: 1000 * 10,
            transports: ['websocket']

        });

        this.setEventsListeners();

    }

    static disconnect() {
        this.socket.disconnect(true);
    }


    static setEventsListeners() {


        this.socket.on(CONNECTED, () => {

            console.log(`PS : ${this.socket.id} connect succesfully`)

            this.socket.emit(ON_SUBSCRIBE_TO_PRINCIPAL_SOCKET_SEND, {})


        });

        this.socket.on("error", (error) => {
            console.log(error);

        });

        this.socket.io.on("connect_error", (err) => {
            console.log(`PS : ${this.socket.id} connect_error due to ${err.message}`);

        });

        this.socket.on("connect_error", async (err) => {

            console.log(`PS : ${this.socket.id} connect_error due to ${err.message}`);
            const $currentUser = localStorage.getItem('currentUser');

            if (err.message == 'PLEASE_AUTHENTICATE' && $currentUser !== null && $currentUser.uuid !== "undefined") {
                const access_token = await AuthService.refreshTokens();
                this.socket.io.opts.query.token = access_token?.data?.tokens?.access?.token.token;
                this.socket.connect();
            }

        });


        this.socket.io.on("reconnect_attempt", (attempt) => {
            console.log(`PS  reconnect attempt`, attempt);
        });

        this.socket.io.on("reconnect", () => {
            // ...
            console.log(`PS : ${this.socket.id} reconnect succesfully`);
            this.socket.emit(ON_SUBSCRIBE_TO_PRINCIPAL_SOCKET_SEND, {})

        });

        this.socket.io.on("reconnect_failed", () => {
            // ...
            console.log(`PS : ${this.socket.id} reconnect_failed `)
        });

        this.socket.on("disconnect", (reasons) => {
            console.log(`PS  disconnect due to  disconnect`, reasons); // false
        });


        /**
         * Listening for incoming new private message
         */
        this.socket.on(PRIVATE_MESSAGES_RECEIVED, (data) => {

            if (Array.isArray(data) && data.length > 0) {


                const msg = data[0];
                //console.log(msg);

                /*app.$store.commit('toast/NEW', {
                    type: 'info',
                    message: "Vous avez un nouveau message de " + msg.sender,
                    vm: app.$toast
                });*/
                const actualOpenConversationPersonUuid = app.$store.getters['privateMessaging/getActualOpenedConversationUuid']
                const unReadCountMessage = app.$store.getters['privateMessaging/getUnReadCountMessage'] ;

                //console.log(msg);

                if (actualOpenConversationPersonUuid !== msg.SenderUuid ) {

                    this.showNotificationPopup(msg);
                    this.updateIndividualUnreadMessageCountAndPutLatMsgContent(msg)
                    app.$store.commit('privateMessaging/SET_UNREAD_COUNT_MESSAGE', unReadCountMessage + 1);
                }


            }

        });

        /**
         * Listening to disconnection hook
         */
        this.socket.on(DISCONNECTED, () => {
            console.log(DISCONNECTED, ': disconnect fril socket');

        });

        /**
         * Listening for socket errors
         */
        this.socket.on(SOCKET_ERROR, async (data) => {

            const $currentUser = localStorage.getItem('currentUser');

            if (data.statusCode == 401 && $currentUser !== null && $currentUser.uuid !== "undefined") {
                const access_token = await AuthService.refreshTokens();
                this.socket.io.opts.query.token = access_token?.data?.tokens?.access?.token.token;
                this.socket.connect();
            }

        });

        /**
         * Listening to subscribe double session management.
         */
        this.socket.on(ON_SUBSCRIBE_TO_PRINCIPAL_SOCKET_RECEIVED, async (data) => {

            if (data.statusCode === 403) {

                await AuthService.makeLogout(true);

            }

        })


    }

    static showNotificationPopup(msg){

        app.$store.commit('toast/NEW', {
            type: 'info',
            message: "Vous avez un nouveau message de " + msg.sender,
            vm: app.$toast
        });
    }

    static updateIndividualUnreadMessageCountAndPutLatMsgContent(msg) {
        let privateConversationsHistory = app.$store.getters['privateMessaging/getprivateConversationsHistory'];
        let i = privateConversationsHistory.findIndex(x => {

            if (app.$currentUser.teacherStatus === false) {
                return x.Module?.Creator?.uuid === msg.SenderUuid;
            } else {
                return x.Profile.uuid === msg.SenderUuid;
            }
        })

        if (i >= 0) {
            let targetPerson = privateConversationsHistory[i];
            if('unReadCountMessage' in targetPerson){
                targetPerson.unReadCountMessage = targetPerson.unReadCountMessage+1
            } else{
                //console.log('else');
                targetPerson.unReadCountMessage = 1
                //console.log(targetPerson)
            }
            //targetPerson?.unReadCountMessage ? targetPerson.unReadCountMessage = targetPerson.unReadCountMessage+1 : targetPerson.unReadCountMessage = 1;
            targetPerson.lastMessage = msg.content;
            targetPerson.lastMessageDateTime = msg.createdAt
            privateConversationsHistory[i] = targetPerson;
            app.$store.commit('privateMessaging/SET_PRIVATE_CONVERSATIONS_HISTORY', privateConversationsHistory);
        }

    }

}

