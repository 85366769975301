
export default {
    // data
    getAllLearningModulesList: state => state.allLearningModulesList,
    getTeacherModulesRetrievedByTeacherCode: state => state.teacherModulesRetrievedByTeacherCode,
    getConnectedTeacherModules: state => state.connectedTeacherModules,
    getConnectedStudentModules: state => state.connectedStudentModules,
    getStudentsByLearningModule: state => state.studentsByLearningModule,

    // data's loaders state getters
    areAllLearningModulesListLoading: state => state.areAllLearningModulesListLoading,
    areTeacherModulesRetrievedByTeacherCodeLoading: state => state.areTeacherModulesRetrievedByTeacherCodeLoading,
    areConnectedTeacherModulesLoading: state => state.areConnectedTeacherModulesLoading,
    areSendingLearningModuleRegistrationLoading: state => state.areSendingLearningModuleRegistrationLoading,
    areConnectedStudentModulesLoading: state => state.areConnectedStudentModulesLoading,
}