export default {
  // data
  allTeachersProfilesList: [],

  allStudentsProfilesList: [],

  allAdminsProfilesList: [],
  allModuleDelationRequest:[],
  currentTeacherModules: [],
  teacherProfilesTotalCount:0,
  studentProfilesTotalCount:0,
  adminProfilesTotalCount:0,
  currentTeacherModulesTotalCount:0,
  teacherProfilesCurrentOffset:0,
  studentProfilesCurrentOffset:0,
  adminProfilesCurrentOffset:0,
  currentTeacherModulesOffset:0,


  // for data loading (data loaders)
  areAllTeachersProfilesListLoading: true,
  areAllStudentsProfilesListLoading: true,
  areAllAdminsProfilesListLoading: true,
  areCurrentTeacherModuleLoading : true,
  areRegisteredStudentsLoading: true
}
