/* eslint-disable no-unused-vars */
import {LivePlanningService} from "@/services/live-planning.service"

export default {
    async createLiveAction({commit}, {data}) {
        try {
            const response = await LivePlanningService.createLive({queryBody: data})
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    },

    async updateALiveAction({commit}, {liveUuid, data}) {
        try {
            const response = await LivePlanningService.updateLearningModule({liveUuid: liveUuid, queryBody: data})
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    },

    async getLiveByTeacherCodeAction({commit}, {teacherCode}) {
        try {
            const response = await LivePlanningService.getLiveByTeacherCode({teacherCode: teacherCode})
            commit('SET_TEACHER_LIVES_RETRIEVED_BY_TEACHER_CODE', response.data)
            return response.data.data
        } catch (error) {
            console.log(error)
        }
    },

    async getLiveByStudentsAction({commit}, {studenProfileUuid}) {
        try {
            const response = await LivePlanningService.getLiveByStudent({studenProfileUuid: studenProfileUuid})
            commit('SET_TEACHER_LIVES_RETRIEVED_BY_STUDENT', response.data)
            return response.data
        } catch (error) {
            console.log(error)
        }
    },
}