<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

import {PrincipalSocketInstance} from "@/SocketsInstances/PrincipalSocketInstance";

export default {
  updated() {
    window.scrollTo({
      top: 0,
      left: 100,
      behavior: 'smooth'
    });

    const currentUser = localStorage.getItem('currentUser');

    if (currentUser !== null) {

      if (this.$currentUser.uuid && this.$currentUser.role !== 3) {


        if (PrincipalSocketInstance.socket == null || !PrincipalSocketInstance.socket.connected) {
          PrincipalSocketInstance.connectToIo();

        }


      }

    }

    window.onerror = function(message, source, line, column, error) {
      console.log(message,source,line,column,error);

    }


  },


  created() {

    /*EventBus.$on('newMessage', (data) => {

      if (!Object.is(this.$router.currentRoute.name, 'private-messaging')) {

        this.$store.commit('toast/NEW', {
          type: 'info',
          message: "Vous avez un nouveau message de " + data.sender,
          vm: this.$toast
        });

        this.$store.commit('privateMessaging/SET_UNREAD_COUNT_MESSAGE', this.getUnReadCountMessage + 1);
      }

    });*/

    window.addEventListener("error", (event) => {
      console.log('------------- EVENT LOGGIN -------');
      console.log(event);
      console.log('------------- END EVENT LOGGIN -------');
    });

    //window.addEventListener()onerror = (event, source, lineno, colno, error) => {};


  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
