import {Http} from "@/axios";
import {ErrorWrapper} from "@/services/util";

export class OttManagerService {

  static request(status = { auth: false }) {
    return new Http(status)
  }

  // static axios= new Http({auth:true})

  static fetchOtts = async () => {
    try {
      return OttManagerService.request({auth: true}).get(`ott`)
    } catch (error) {
      console.log(error)
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}

