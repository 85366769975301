import axios from 'axios'
import {AuthService} from '@/services/auth';
import {API_URL,API_SERVER_URL} from './.env.js';
import $router from './router'
import {ForumsService} from "@/services/forums.service";
import {PrivateMessagesService} from "@/services/privateMessages.service";

export class Http {
  //static axios = axios;
  //axios_instance = axios.create();

  constructor(status) {
    this.isAuth = status && status.auth ? status.auth : false;

    this.instance = axios.create({
      baseURL: status && !status.isApiServerRequest ?  API_URL : API_SERVER_URL ,
      headers: {}
    })
    return this.initRequestFetching();
  }

  initRequestFetching() {
    if (this.isAuth) {
      this.instance.interceptors.request.use(config => {
        config.headers.authorization = AuthService.getBearer()
        return config
      }, error => {
        return Promise.reject(error);
      });
    }

    this.instance.interceptors.response.use(function (response) {
      return response;
    }, async function (error) {
      const originalRequest = error.config;

      if (error.response) {
        if (error.response.status === 401 && originalRequest.url === `${API_URL}/auth/refresh-token` && originalRequest.url !== `${API_URL}/auth/login`) {
          await AuthService.makeLogout();

          return Promise.reject(error);
        }

        if (error.response.status === 403 && ["TEACHER_DESCRIPTION_NOT_FILLED", "TEACHER_PROFILE_PICTURE_NOT_FILLED"].includes(error.response.data.message)) {
          $router.push({ name: 'personnal-informations', params: { incompleteProfile: true } })
          return Promise.reject(error);
        }


        if (error.response.status === 401 && !originalRequest._retry && originalRequest.url !== `${API_URL}/auth/login` && originalRequest.url !== `${API_URL}/auth/refresh-token`) {

          originalRequest._retry = true;
          try {
            const access_token = await AuthService.refreshTokens();
            let axios_instance = axios.create();

            originalRequest.headers.authorization = 'Bearer ' + access_token.data.tokens.access.token.token;

            return axios_instance(originalRequest);
          } catch (e) {
            console.log(e);
          }

        }
      }
      return Promise.reject(error)
    });
    return this.instance
  }


}


