export default {
    // data
    getprivateConversations: state => state.privateConversations,
    getprivateConversationsHistory: state => state.privateConversationsHistory,
    getActualOpenedConversationUuid: state => state.actualOpenedConversationPersonUuid,
    getUnReadCountMessage: state => state.unReadCountMessage,

    // data's loaders state getters
    arePrivateConversationsLoading: state => state.arePrivateConversationsLoading,
    areLoadMorePrivateMessage: state => state.areLoadMoreMessage

}
