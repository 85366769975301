/* eslint-disable no-unused-vars */
import * as Fingerprint2 from 'fingerprintjs2'
import * as UAParser from 'ua-parser-js'
import { Http } from '../axios';

import { ResponseWrapper, ErrorWrapper } from './util'
import $store from '../store'
import $router from '../router'

import { API_URL } from '../.env'
import {app} from "@/main";
import currentUser from '@/mixins/currentUser';
import user from '@/store/modules/user';
import {ForumsService} from "@/services/forums.service";
import {PrivateMessagesService} from "@/services/privateMessages.service";
import {PrincipalSocketInstance} from "@/SocketsInstances/PrincipalSocketInstance";
import {uuid} from "vue-uuid";

export class AuthService {
  /**
     ******************************
     * @API
     ******************************
  **/
  static request(status = { auth: false }) {
    return new Http(status)
  }

  static async makeLogin(payload) {
    try {

      const response = await this.request().post(`${API_URL}/auth/login`, payload /*, { withCredentials: true } */)

      _setAuthData({
        accessToken: response.data.tokens.access.token.token,
        exp: response.data.tokens.access.token.expires

      })

      _setCurrentUser(response.data.user)

      AuthService.setRefreshToken(response.data.tokens.refresh.token.token)
      AuthService.setAccessToken(response.data.tokens.access.token.token)

      return new ResponseWrapper(response, response.data)
    } catch (error) {

      throw new ErrorWrapper(error, error.response.data.message)
    }
  }

  static async makePasswordResetRequest({ email }) {
    try {
      const response = await this.request().post(`${API_URL}/auth/ask-password-reset`, { 'email': email } /*, { withCredentials: true } */)

      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async makeResetPassword({ password, resetToken }) {
    try {
      const response = await this.request().post(`${API_URL}/auth/reset-password/${resetToken}`, { 'newPassword': password } /*, { withCredentials: true } */)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async makeUpdatePassword({ oldPassword, newPassword }) {
    try {

      const response = await this.request({ auth: true }).post(`${API_URL}/auth/online-reset-password`, { 'newPassword': newPassword, 'oldPassword': oldPassword } /*, { withCredentials: true } */)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }


  static async makeActivateAccount({ token }) {
    try {
      const response = await this.request().post(`${API_URL}/auth/verify-email?token=${token}` /*, { withCredentials: true } */)

      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async makeRegistration({ email, password, accountType, ProfileIdentity }) {
    try {
      const queryBody = {
        'email': email,
        'password': password,
        'accountType': accountType,
        'ProfileIdentity': ProfileIdentity
      }
      const response = await this.request().post(`${API_URL}/auth/register`, queryBody)

      $router.push({ name: 'login' }).catch(() => { })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async makeRegistrationStudent({ queryBody }) {

    try {

      const response = await this.request().post(`${API_URL}/auth/register/student`, queryBody)

      $router.push({ name: 'login' })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async makeRegistrationTeacher({ queryBody }) {
    try {
      const response = await this.request().post(`${API_URL}/auth/register/teacher`, queryBody)

      $router.push({ name: 'login' })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      //console.log(error);
      throw new ErrorWrapper(error)
    }
  }
  static async makeRegistrationAdmin({ queryBody }) {
      try {
        const response = await this.request().post(`${API_URL}/auth/register/admin`, queryBody)

        return new ResponseWrapper(response, response.data.data)
      } catch (error) {
        //console.log(error);
        throw new ErrorWrapper(error)
      }
    }

  static async makeUpdateProfile({ role, queryBody }) {
    //console.log(queryBody)
    try {
      var prefix = ''
      delete queryBody.email;
      if (role === 1) {
        prefix = '/student'
        delete queryBody.teacherDescription
      }

      if (role === 2) {
        prefix = '/teacher'
        queryBody = {
          "teacherDescription": queryBody.teacherDescription,
          "profilePicture": queryBody.profilePicture,
          "ProfileIdentity": {
            "firstname": queryBody.ProfileIdentity.firstname,
            "lastname": queryBody.ProfileIdentity.lastname,
            "phone": queryBody.ProfileIdentity.phone
          }
        }
      }

      if (role === 3) {
        prefix = '/admin'
        delete queryBody.teacherDescription
        delete queryBody.establishment
        delete queryBody.faculty
        delete queryBody.level
        queryBody = {
          "profilePicture": queryBody.profilePicture,
          "ProfileIdentity": {
            "firstname": queryBody.ProfileIdentity.firstname,
            "lastname": queryBody.ProfileIdentity.lastname,
            "phone": queryBody.ProfileIdentity.phone
          }
        }
      }

      if (queryBody.profilePicture === "" || queryBody.profilePicture === null) {
        delete queryBody.profilePicture;
      }
      const response = await this.request({ auth: true }).put(`${API_URL}/profile${prefix}`, queryBody)

      _setCurrentUserProfile(response.data, role);

      return new ResponseWrapper(response, response.data)
    } catch (error) {

      throw new ErrorWrapper(error)
    }
  }
  static async generateAwsUploadSecureLink(profileUuid, profilePicture){
    try {

      const response = await this.request({ auth: true }).post(`${API_URL}/media/upload-file?fileName=${profilePicture.name}&moduleUuid=${uuid.v4()}&contentType=image/png&type=profilePicture`)
      return new ResponseWrapper(response, response)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async makeUploadProfilePicture(securedUrl, profilePicture) {
    try {

      const binaryFile = new Blob([profilePicture]);
      const response = await this.request({ auth: false }).put(`${securedUrl}`, binaryFile)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async makeRegistrationWithFacebook({ accessToken, inputToken }) {
    try {
      const fingerprint = await _getFingerprint()
      const response = await this.request().post(`${API_URL}/auth/facebook-register`, { 'accessToken': accessToken, 'inputToken': inputToken } /*, { withCredentials: true } */)
      _setAuthData({
        accessToken: response.data.tokens.access.token,
        exp: response.data.tokens.access.expires
        // exp: _parseTokenData(response.data.tokens.access.expires)
      })
      _setCurrentUser(response.data.user)

      AuthService.setRefreshToken(response.data.tokens.refresh.token)
      AuthService.setAccessToken(response.data.tokens.access.token)

      $router.push({ name: 'login' }).catch(() => { })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async makeRegistrationWithGoogle({ idToken }) {
    try {
      const fingerprint = await _getFingerprint()
      const response = await this.request().post(`${API_URL}/auth/google-register`, { 'id_token': idToken } /*, { withCredentials: true } */)
      _setAuthData({
        accessToken: response.data.tokens.access.token,
        exp: response.data.tokens.access.expires

      })
      _setCurrentUser(response.data.user)

      AuthService.setRefreshToken(response.data.tokens.refresh.token)
      AuthService.setAccessToken(response.data.tokens.access.token)

      $router.push({ name: 'login' }).catch(() => { })
      return new ResponseWrapper(response, response.data.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async makeLogout(dueToDoubleSession = false) {
    try {
      // const response = await new Http({ auth: true }).post('auth/logout', {}, { withCredentials: true })


      _resetAuthData()
      if(dueToDoubleSession === true){
      $router.push({ name: 'login', query: { isDoubleSession: dueToDoubleSession } })
      } else {
        $router.push({ name: 'login'})
      }
      return new ResponseWrapper({ 'data': 'success' }, 'sucesss')
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async refreshTokens() {

    try {

      const response = await this.request({ auth: true }).post(`${API_URL}/auth/refresh-token`, {
        'refreshToken': localStorage.getItem('refreshToken')
      })


      _setAuthData({
        accessToken: response.data.tokens.access.token.token,
        exp: response.data.tokens.access.token.expires
      })


      AuthService.setRefreshToken(response.data.tokens.refresh.token.token)
      AuthService.setAccessToken(response.data.tokens.access.token.token);

      return new ResponseWrapper(response, response.data)
    } catch (error) {

      _resetAuthData();
      $router.push({ name: 'login' })/*.catch(() => { })*/
      //throw new ErrorWrapper(error)
    }
  }

  static async makeResendActivationMail({ email }) {
    try {
      const fingerprint = await _getFingerprint()
      const response = await this.request().post(`${API_URL}/auth/resend-verification-email`, { 'email': email } /*, { withCredentials: true } */)

      return new ResponseWrapper(response, response.data)
    } catch (error) {

      throw new ErrorWrapper(error, error.response.data.message)
    }
  }

  static async sendMessageToETutoratSupport({/* lastname, firstname,*/ email, message }) {
    try {

      const response = await this.request({ auth: true }).post(`${API_URL}/contact/`, { /*'lastname': lastname, 'firstname': firstname,*/ email: email, 'message': message } /*, { withCredentials: true } */)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }


  /**
   ******************************
   * @METHODS
   ******************************
   */

  static isAccessTokenExpired() {
    const accessTokenExpDate = (new Date(localStorage.getItem('expireAccesTokenTime'))).getTime() / 1000
    const nowTime = Math.floor(new Date().getTime() / 1000)

    return accessTokenExpDate <= nowTime
  }

  static hasRefreshToken() {
    return Boolean(localStorage.getItem('refreshToken'))
  }

  static setRefreshToken(token) {
    localStorage.setItem('refreshToken', token)
  }
  static setAccessToken(token) {
    localStorage.setItem('accessToken', token)
  }

  static getBearer() {

      var token = localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : null;

      return `Bearer ${token}`

  }


}

/**
 ******************************
 * @private_methods
 ******************************
 */

function _parseTokenData(accessToken) {
  let payload = ''
  let tokenData = {}

  try {
    payload = accessToken.split('.')[1]
    tokenData = JSON.parse(atob(payload))
  } catch (error) {
    throw new Error(error)
  }

  return tokenData
}

function _resetAuthData() {
  // reset userData in store
  if (app.$currentUser.role !== 3){
    ForumsService.disconnect();
    PrivateMessagesService.disconnect();
    PrincipalSocketInstance.disconnect();
  }

  $store.commit('user/SET_CURRENT_USER', {})
  $store.commit('auth/SET_ATOKEN_EXP_DATE', null)
  // reset tokens
  localStorage.removeItem('currentUser')
  localStorage.removeItem('expireAccesTokenTime')
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  //localStorage.clear()


}

function _setAuthData({ accessToken, exp } = {}) {


  localStorage.setItem('expireAccesTokenTime', exp)

  $store.commit('auth/SET_ATOKEN_EXP_DATE', exp)
}

function _setCurrentUser(user) {
  $store.commit('user/SET_CURRENT_USER', user)

  localStorage.setItem('currentUser', JSON.stringify(user))
}

function _setCurrentUserProfile(profile, role) {
  try {

    var user = JSON.parse(localStorage.getItem('currentUser'));
    if (role === 1) {
      user.ProfileIdentity.firstname = profile.ProfileIdentity.firstname;
      user.ProfileIdentity.lastname = profile.ProfileIdentity.lastname;
      user.ProfileIdentity.phone = profile.ProfileIdentity.phone;
      user.teacherDescription = profile.teacherDescription;
    }

    if (role === 2) {
      user.ProfileIdentity.firstname = profile.ProfileIdentity.firstname;
      user.ProfileIdentity.lastname = profile.ProfileIdentity.lastname
      user.ProfileIdentity.phone = profile.ProfileIdentity.phone
      user.ProfileIdentity.establishment = profile.ProfileIdentity.establishment;
      user.ProfileIdentity.faculty = profile.ProfileIdentity.faculty;
      user.ProfileIdentity.level = profile.ProfileIdentity.level;
    }

    if (role === 3) {
      user.ProfileIdentity.firstname = profile.ProfileIdentity.firstname;
      user.ProfileIdentity.lastname = profile.ProfileIdentity.lastname
      user.ProfileIdentity.phone = profile.ProfileIdentity.phone
    }

    $store.commit('user/SET_CURRENT_USER', user)
    localStorage.setItem('currentUser', JSON.stringify(user))
  } catch (error) {
    console.log(error);
  }
}

function _getFingerprint() {
  return new Promise((resolve, reject) => {
    async function getHash() {
      const options = {
        excludes: {
          plugins: true,
          localStorage: true,
          adBlock: true,
          screenResolution: true,
          availableScreenResolution: true,
          enumerateDevices: true,
          pixelRatio: true,
          doNotTrack: true,
          preprocessor: (key, value) => {
            if (key === 'userAgent') {
              const parser = new UAParser(value)
              // return customized user agent (without browser version)
              return `${parser.getOS().name} :: ${parser.getBrowser().name} :: ${parser.getEngine().name}`
            }
            return value
          }
        }
      }

      try {
        const components = await Fingerprint2.getPromise(options)
        const values = components.map(component => component.value)


        return String(Fingerprint2.x64hash128(values.join(''), 31))
      } catch (e) {
        reject(e)
      }
    }

    if (window.requestIdleCallback) {
      // console.log('get fp hash @ requestIdleCallback')
      requestIdleCallback(async () => resolve(await getHash()))
    } else {
      // console.log('get fp hash @ setTimeout')
      setTimeout(async () => resolve(await getHash()), 500)
    }
  })
}
