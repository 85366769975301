import Vue from 'vue'
import VueRouter from 'vue-router';

import {  checkAccessMiddleware } from './middlewares'
//import ModulesByStudentsView from "@/views/admin-side-views/ModulesByStudentsView.vue";

// layouts
const AuthLayout = () => import('@/layouts/AuthLayout.vue')
const DefaultLayout = () => import('@/layouts/DefaultLayout.vue')
const AppLayout = () => import('@/layouts/AppLayout.vue')
const InfosPagesLayout = () => import('@/layouts/InfosPagesLayout.vue')

// Auth Pages
const LoginPage = () => import('@/pages/auth/LoginPage.vue')
const StudentRegistrationPage = () => import('@/pages/auth/StudentRegistrationPage.vue')
const TeacherRegistrationPage = () => import('@/pages/auth/TeacherRegistrationPage.vue')
const PasswordForgottenPage = () => import('@/pages/auth/PasswordForgottenPage.vue')
const PasswordResetPage = () => import('@/pages/auth/PasswordResetPage.vue')
const UpdatePasswordPage = () => import('@/pages/auth/UpdatePasswordPage.vue');

// Common pages
const ProfilePage = () => import('@/pages/auth/ProfilePage.vue')
const AboutPage = () => import('@/pages/informations/AboutPage.vue')
const ContactUsPage = () => import('@/pages/informations/ContactUsPage.vue')
const FAQPage = () => import('@/pages/informations/FAQPage.vue')
const PrivacyPolicyPage = () => import('@/pages/informations/PrivacyPolicyPage.vue')
/*************** Views ***************/

// common views
const HomeView = () => import('@/views/HomeView.vue')
const PrivateMessagesView = () => import('@/views/PrivateMessagesView.vue')

// students side
const ResearchTeacherView = () => import('@/views/students-side-views/ResearchTeacherView.vue')
const ModuleVisualizationView = () => import('@/views/students-side-views/ModuleVisualizationView.vue')
const StudentModulesListView = () => import('@/views/students-side-views/StudentModulesListView.vue')

// teacher side
const TeacherModulesListView = () => import('@/views/teacher-side-views/TeacherModulesListView.vue')
const ModuleView = () => import('@/views/teacher-side-views/ModuleView.vue')

// admin side
const StudentsManagementView = () => import('@/views/admin-side-views/StudentsManagementView.vue')
const TeachersManagementView = () => import('@/views/admin-side-views/TeachersManagementView.vue')
const AdminsManagementView = () => import('@/views/admin-side-views/AdminsManagementView.vue')
const ModulesByTeacherView = () => import('@/views/admin-side-views/ModulesByTeacherView.vue')
const ModulesByStudentView = () => import('@/views/admin-side-views/ModulesByStudentsView.vue')
const RegisteredStudentsByModuleView = () => import('@/views/admin-side-views/RegisteredStudentsByModuleView.vue')

// Error Pages
const Page404 = () => import('@/pages/errors/404Page.vue')
const Page403 = () => import('@/pages/errors/403Page');

Vue.use(VueRouter)

const routes = [
  // Errors
  { path: "*", component: Page404 ,
    meta: {isAuth: false,neededRole: [] },
  },

  {
    path: "/forbbiden",
    name:'forbidden-access',
    meta: {isAuth: true,neededRole: [1,2,3]},
    component: AuthLayout,
    children: [
      {
        path: "",
        name:'forbidden',
        component: Page403,
      }
    ]

  },

  // Informations
  {
    path: '/infos',
    name: 'infos',
    meta: { isAuth: false, neededRole: [] },
    component: InfosPagesLayout,
    children: [
      // about
      {
        path: 'about',
        name: 'about',
        component: AboutPage,
      },

      // contact us
      {
        path: 'contact-us',
        name: 'contact-us',
        component: ContactUsPage,
      },

      // faq
      {
        path: 'faq',
        name: 'faq',
        component: FAQPage,
      },

      // privacy policy
      {
        path: 'privacy-policy',
        name: 'privacy-policy',
        component: PrivacyPolicyPage,
      },
    ]
  },

  // Authentication
  {
    path: '/auth',
    name: 'auth',
    meta: {isAuth: false,neededRole: []},
    component: AuthLayout,
    children: [
      // login
      {
        path: 'login',
        name: 'login',
        component: LoginPage,

      },
      // Verify email
      {
        path: 'verify-email',
        name: 'verify-email',
        component: LoginPage,
      },
      // student registration
      {
        path: 'register/student',
        name: 'student-registration',
        component: StudentRegistrationPage,
      },
      // teacher registration
      {
        path: 'register/teacher',
        name: 'teacher-registration',
        component: TeacherRegistrationPage,
      },
      // password forgotten
      {
        path: 'password-forgotten',
        name: 'password-forgotten',
        component: PasswordForgottenPage,
      },

      // reset password
      {
        path: 'reset-password',
        name: 'reset-password',
        component: PasswordResetPage,
      }

    ]
  },

  // App
  {
    path: '/',
    redirect: '/',
    meta: {isAuth: false, neededRole: []},
    component: DefaultLayout,
    children: [
      // Home
      {
        path: '/',
        name: 'home',
        component: HomeView,
      },

      // Research teacher
      {
        path: '/research',
        name: 'research-teacher',
        component: ResearchTeacherView,

      },
    ]
  },

  // Private Messaging
  {
    path: '/messaging',
    component: AppLayout,
    meta: {isAuth: true, neededRole: [1,2,3]},
    children: [
      {
        path: 'private-messages',
        name: 'private-messaging',
        component: PrivateMessagesView,
      },
    ]
  },

  // Modules
  {
    path: '/modules',
    meta: {isAuth: true, neededRole: [1,3]},
    component: AppLayout,
    children: [
      // Visualize
      {
        path: ':uuid',
        name: 'selected-module',
        props: true,
        component: ModuleVisualizationView,

      },
    ]
  },

  // Profile
  {
    path: '/my-profile',
    component: AppLayout,
    meta: {isAuth: true, neededRole: [1,2,3]},
    children: [
      // Profile
      {
        path: 'personnal-informations',
        name: 'personnal-informations',
        props: true,
        component: ProfilePage,

      },

      // Update password
      {
        path: 'update-password',
        name: 'update-password',
        props: true,
        component: UpdatePasswordPage,

      },
    ]
  },

  // Student space
  {
    path: '/student-space',
    meta: {isAuth: true, neededRole: [1,2,3]},
    component: AppLayout,

    children: [
      // Teacher modules list
      {
        path: 'my-modules',
        name: 'student-modules-list',
        component: StudentModulesListView,
      },
    ]
  },

  // Teacher space
  {
    path: '/teacher-space',
    meta: {isAuth: true, neededRole: [2,3]},
    component: AppLayout,
    children: [
      // Teacher modules list
      {
        path: 'my-modules',
        name: 'modules-list',
        component: TeacherModulesListView,

      },

      // Module view
      {
        path: 'my-modules/:moduleUuid',
        name: 'module-view',
        component: ModuleView,

      },
    ]
  },

  // Admin space
  {
    path: '/admin',
    redirect: '/admin/students-management',
    meta: {isAuth: true, neededRole: [3]},
    component: AppLayout,
    children: [
      // Students management
      {
        path: 'students-management',
        name: 'students-management',
        component: StudentsManagementView,


      },

      {
        path: 'students-modules/:uuid',
        name: 'students-modules-list-for-admin',
        component: ModulesByStudentView,
      },

      // Teachers management
      {
        path: 'teachers-management',
        name: 'teachers-management',
        component: TeachersManagementView,

      },

      // Teachers modules
      {
        path: 'teachers-management/:teacherCode/modules/:moduleUuid',
        name: 'modules-by-teacher',
        component: ModulesByTeacherView,


      },

      // Registered students by teacher module
      {
        path: 'teachers-management/:teacherCode/modules/:teacherModuleUuid/registered-students',
        name: 'registered-students-by-module',
        component: RegisteredStudentsByModuleView,

      },

      // Admins management
      {
        path: 'admins-management',
        name: 'admins-management',
        component: AdminsManagementView,

      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(checkAccessMiddleware);
export default router
