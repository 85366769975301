/* eslint-disable no-unused-vars */
import { VideoModuleService } from "@/services/video-module.service"
import { ErrorWrapper} from '../../../services/util'
import {OttManagerService} from "@/services/ottManagerService";
export default {
  async addVideoToModule ({ commit }, { data }) {
    try {
      const response = await VideoModuleService.addVideoToModule({ queryBody: data })
      return response.data
    } catch (error) {
      throw new ErrorWrapper(error, error.message)
    }
  },

  async fetchOtts ({commit} ) {
    try {
      console.log("**** in fetchOtt ***")
      const response = await OttManagerService.fetchOtts()
      console.log(`*** ${response.data.length} fetched ***`)
      commit('SET_OTTS', response.data)
    } catch (error) {
      throw new ErrorWrapper(error, error.message)
    }
  },

  async getAllVideoModuleAction ({ commit }, learningModuleUuid) {
    
    try {
      const response = await VideoModuleService.getAllVideoModule(learningModuleUuid)
      commit('SET_ALL_VIDEO_MODULE_LIST', response.data)
      return response.data
    } catch (error) {
      throw new ErrorWrapper(error, error.message)
    }
  },

  async streamVideoModuleAction ({ commit }, {videoModuleUuid, learningModuleUuid, token }) {
    commit('SET_ARE_STREAM_VIDEO_MODULE',true);
    try {
      const response = await VideoModuleService.streamVideoModule({videoModuleUuid : videoModuleUuid, learningModuleUuid : learningModuleUuid, token : token })
      commit('SET_STREAM_VIDEO_MODULE', response.data);
      commit('SET_ARE_STREAM_VIDEO_MODULE',false);
     
      return response.data
    } catch (error) {
      commit('SET_ARE_STREAM_VIDEO_MODULE',false);
      throw new ErrorWrapper(error, error.message)
    }
  },

  async updateAVideoModuleAction ({ commit }, { videoModuleUuid, data }) {
    try {
      const response = await VideoModuleService.updateVideoModule({ videoModuleUuid: videoModuleUuid, queryBody: data })
      return response.data
    } catch (error) {
      throw new ErrorWrapper(error, error.message)
    }
  },

}