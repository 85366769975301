export default {
    // data mutations
    SET_TEACHER_LIVES_RETRIEVED_BY_TEACHER_CODE(state, value) {
        state.teacherLivesRetrievedByTeacherCode = value
    },

    SET_TEACHER_LIVES_RETRIEVED_BY_STUDENT(state, value) {
        state.connectedStudentLivesSubribed = value
    },

    // data's loaders mutations
    SET_ARE_TEACHER_LIVES_RETRIEVED_BY_TEACHER_CODE_LOADING(state, value) {
        state.areTeacherLivesRetrievedByTeacherCodeLoading = value
    },

    SET_ARE_TEACHER_LIVES_RETRIEVED_BY_STUDENT_LOADING(state, value) {
        state.areConnectedStudentLivesSubribedLoading = value
    },

}