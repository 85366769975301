import {ErrorWrapper, ResponseWrapper} from './util'
import {Http} from '../axios';
import {uuid} from "vue-uuid";

export class LearningModuleService {
    /**
     ******************************
     * @API_CALLS
     ******************************
     **/
    static request(status = {auth: true}) {
        return new Http(status)
    }

    static get moduleEntity() {
        return 'module'
    }

    static get moduleDelectionRequestEntity() {
        return 'module-deletion-request'
    }

    static get moduleUserEntity() {
        return 'module-user'
    }

    static async generateAwsUploadSecureLink(fileName, contentType, type) {
        try {

            const response = await this.request({auth: true}).post(`media/upload-file?fileName=${fileName}&moduleUuid=${uuid.v4()}&contentType=${contentType}&type=${type}`)

            return new ResponseWrapper(response, response);

        } catch (error) {
            const message = error.response.data ? error.response.data.message : error.response.statusText
            throw new ErrorWrapper(error, message)
        }
    }

    static async createLearningModule(queryBody) {
        try {

            var tempFile = queryBody.coverPicture

            delete queryBody.coverPicture;

            const response = await this.request().post(`${this.moduleEntity}/`, queryBody)
            queryBody.coverPicture = tempFile

            const uploadModuleCoverSecuredLink = await this.generateAwsUploadSecureLink(`${response.data.uuid}_${queryBody.coverPicture.name}`, "image/png", "moduleCover")

            // console.log(uploadModuleCoverSecuredLink);

            const uploadResponse = await this.uploadLearningModuleCoverImage(uploadModuleCoverSecuredLink?.data.data.url, queryBody)
            const finalResponse = {
                moduleData: response.data,
                moduleCoverData: {filePath: `${response.data.uuid}_${queryBody.coverPicture.name}`}
            }
            return new ResponseWrapper(uploadResponse, finalResponse)
        } catch (error) {
            const message = error.response.data ? error.response.data.message : error.response.statusText
            throw new ErrorWrapper(error, message)
        }
    }

    static async uploadLearningModuleCoverImage(securedUrl, queryBody) {
        try {
            let binaryFile = new Blob([queryBody.coverPicture])
            const response = await this.request({auth: false}).put(`${securedUrl}`, binaryFile)
            return new ResponseWrapper(response, response?.data)

        } catch (error) {
            const message = error.response.data ? error.response.data.message : error.response.statusText
            throw new ErrorWrapper(error, message)
        }
    }

    static async getAllLearningModules() {
        try {
            const response = await this.request().get(`${this.moduleEntity}/all/`)
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            const message = error.response.data ? error.response.data.message : error.response.statusText
            throw new ErrorWrapper(error, message)
        }
    }

    static async getSingleLearningModule(learningModuleUuid) {
        try {
            const response = await this.request().get(`${this.moduleEntity}/${learningModuleUuid}`)
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            const message = error.response.data ? error.response.data.message : error.response.statusText
            throw new ErrorWrapper(error, message)
        }
    }

    static async updateLearningModule(learningModuleUuid, queryBody) {
        try {
            const response = await this.request().put(`${this.moduleEntity}/${learningModuleUuid}`, queryBody)
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            const message = error.response.data ? error.response.data.message : error.response.statusText
            throw new ErrorWrapper(error, message)
        }
    }

    static async getLearningModulesByTeacherCode(teacherCode) {
        try {
            const response = await this.request().get(`${this.moduleEntity}/by-teacher/${teacherCode}`)

            return new ResponseWrapper(response, response.data)
        } catch (error) {

            const message = error.response.data ? error.response.data.message : error.response.statusText
            throw new ErrorWrapper(error, message)
        }
    }

    static async getConnectedTeacherLearningModules() {

        try {
            const response = await this.request().get(`${this.moduleEntity}/by-teacher`)
            return new ResponseWrapper(response, response.data)
        } catch (error) {

            const message = error.response.data ? error.response.data.message : error.response.statusText
            throw new ErrorWrapper(error, message)
        }
    }

    static async getConnectedStudentLearningModules() {

        try {
            const response = await this.request().get(`${this.moduleUserEntity}/by-student`)
            return new ResponseWrapper(response, response.data)
        } catch (error) {

            const message = error.response.data ? error.response.data.message : error.response.statusText
            throw new ErrorWrapper(error, message)
        }
    }

    static async createModuleDeletionRequest(payload) {
        try {
            delete payload.moduleuuid
            const response = await this.request().post(`${this.moduleDelectionRequestEntity}`, payload)
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            const message = error.response.data ? error.response.data.message : error.response.statusText
            throw new ErrorWrapper(error, message)
        }
    }

    static async RegisterLearningModuleRequest({ModuleUuid}) {
        try {
            const response = await this.request().post(`${this.moduleUserEntity}`, {"ModuleUuid": ModuleUuid})
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            const message = error.response.data ? error.response.data.message : error.response.statusText
            throw new ErrorWrapper(error, message)
        }
    }

    static async getStudentsByLearningModule({queryBody}) {
        try {
            const response = await this.request().post(`${this.moduleUserEntity}/all`, queryBody)
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            const message = error.data ? error.data.message : error.statusText
            throw new ErrorWrapper(error, message)
        }
    }

    static async validateSubscription({queryBody}) {
        try {
            const response = await this.request().post(`${this.moduleUserEntity}/register/allow-access`, queryBody)
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            const message = error.data ? error.data.message : error.statusText
            throw new ErrorWrapper(error, message)
        }
    }

    static async manageStudentAccessToModule({queryBody}) {
        try {

            const response = await this.request().post(`${this.moduleUserEntity}/student-access-management`, queryBody)
            return new ResponseWrapper(response, response.data)
        } catch (error) {

            const message = error.data ? error.data.message : error.statusText
            throw new ErrorWrapper(error, message)
        }
    }

    static async manageStudentPaymentToModule({queryBody}) {
        try {

            const response = await this.request().post(`${this.moduleUserEntity}/payment`, queryBody)
            return new ResponseWrapper(response, response.data)
        } catch (error) {

            const message = error.data ? error.data.message : error.statusText
            throw new ErrorWrapper(error, message)
        }
    }

    static async denySubscription({queryBody}) {
        try {
            const response = await this.request().post(`${this.moduleUserEntity}/register/deny-access`, queryBody)
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            const message = error.data ? error.data.message : error.statusText
            throw new ErrorWrapper(error, message)
        }
    }

    static async managePaymentAddition({queryBody}) {
        try {
            const response = await this.request().post(`${this.moduleUserEntity}/payment`, queryBody)
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            const message = error.data ? error.data.message : error.statusText
            throw new ErrorWrapper(error, message)
        }
    }


}
