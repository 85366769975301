
export const API_PORT = '80'
export const API_VERSION = 'v1'
export const API_STAGE = 'prod'

export const API_IMAGE_BASE_URL=`https://ca75sn5l14.execute-api.eu-west-3.amazonaws.com`
export const LIVE_BASE_URL = 'https://live-dev.etutorat.fr'
export const SOCKET_BASE_URL = `https://socket.v2.etutorat.fr`;
export const S3_PROFILE_PICTURE_URL = `https://e-tutorat-profile-picture-prod-us.s3.us-east-1.amazonaws.com`
export const S3_VIDEO_COVER_URL = `https://e-tutorat-module-video-cover-prod-us.s3.us-east-1.amazonaws.com`
export const S3_VIDEO_CONTENT_URL = `https://watch.etutorat.fr`

export const S3_MODULE_COVER_URL = `https://e-tutorat-module-cover-prod-us.s3.us-east-1.amazonaws.com`

export const CONNECTED = 'CONNECTED';
export const DISCONNECTED = 'disconnecting';

export const SOCKET_ERROR = 'SOCKET_ERROR';

export const OLD_MODULE_FORUM_CONVERSATION= 'forum:old_conversation';
export const SEND_MODULE_FORUM_MESSAGE= 'forum:send';
export const RECEIVE_MODULE_FORUM_MESSAGE= 'forum:received';
export const MODULE_FORUM_SUBSCRIBE = 'forum:subscribe';
export const PRIVATE_MESSAGES_SUBSCRIBE = 'messaging:subscribe';
export const PRIVATE_MESSAGES_RECEIVED = 'messaging:received';
export const PRIVATE_MESSAGES_OLD_CONVERSATION = 'messaging:old_conversation';
export const SEND_PRIVATE_MESSAGE= 'messaging:send';
export const ON_SUBSCRIBE_TO_PRINCIPAL_SOCKET_RECEIVED = 'ON_SUBSCRIBE_TO_PRINCIPAL_SOCKET_RECEIVED';
export const ON_SUBSCRIBE_TO_PRINCIPAL_SOCKET_SEND = 'ON_SUBSCRIBE_TO_PRINCIPAL_SOCKET_SEND';

export const DOMAIN_TITLE = 'E-TUTORAT'
export const VUE_CONFIG_PRODUCTION_TOOL_TIP = false
export const VUE_CONFIG_DEV_TOOLS = true
export const MODE_MAINTENANCE = false;
export const GTAG_ID = 'G-4J289QXNX6'
export const DEFAULT_ADMIN_DATA_PAGE_SIZE = 50;


export const API_URL=`https://ca75sn5l14.execute-api.eu-west-3.amazonaws.com/${API_STAGE}`;
export const API_SERVER_URL = `https://api.v2.etutorat.fr/${API_STAGE}`;

