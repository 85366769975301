export default {
    methods: {
        $showMsgConfirmationBox() {
      
            this.$bvModal
              .msgBoxConfirm("Vous essayez d'accéder à un cours au quel vous n'êtes pas inscrit. Souhaitez-vous vous inscrire ?.", {
                title: "Information",
                size: "md",
                buttonSize: "md",
                okVariant: "primary",
                okTitle: "OUI",
                cancelTitle: "NON",
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true,
              })
              .then((value) => {
                return value;
              })
              .catch((err) => {
                // An error occurred
              });
          },
    }
}