export default {
    methods: {
        $getFileExtension(fileName) {
            console.log(fileName);
            // Use the lastIndexOf method to find the last occurrence of a period (.)
            const lastIndex = fileName.lastIndexOf('.');

            // Check if a period was found and if it's not the last character in the file name
            if (lastIndex !== -1 && lastIndex < fileName.length - 1) {
                // Use the substring method to extract the file extension
                return fileName.substring(lastIndex + 1).toLowerCase();
            } else {
                // If there's no file extension or it's the last character, return an empty string
                return '';
            }
        }


    }
}
