export default {
    // data mutations
    SET_ALL_VIDEO_MODULE_LIST(state, value) {
        state.allVideoModulesList = value
    },
    SET_STREAM_VIDEO_MODULE(state, value) {
        state.streamVideoModule = value
    },
    SET_OTTS(state,value){
        state.otts=value
    },


    // data's loaders mutations
    SET_ARE_ALL_VIDEO_MODULE_LIST_LOADING(state, value) {
        state.areAllVideoModulesListLoading = value
    },

    SET_ARE_STREAM_VIDEO_MODULE(state, value) {
        state.areStreamVideoModuleLoading = value
    },


}