import { ErrorWrapper, ResponseWrapper } from './util'
import {API_SERVER_URL,API_STAGE} from '../.env'
import {Http} from '../axios'
import {uuid} from "vue-uuid";

export class VideoModuleService {
  /**
     ******************************
     * @API_CALLS
     ******************************
  **/

  static get entity() {
    return 'module-video'
  }

  static request(status = { auth: true }) {
    return new Http(status)
  }

  static async generateAwsUploadSecureLink(fileName,contentType,type){
    try {
      const response = await this.request({ auth: true }).post(`media/upload-file?fileName=${fileName}&moduleUuid=${uuid.v4()}&contentType=${contentType}&type=${type}`)
      return new ResponseWrapper(response, response);
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }


  static async addVideoToModule(queryBody) {
    try {
      var tempFile = queryBody.coverPicture;

      // console.log(queryBody);

      // const uploadVideoSecuredLink = await VideoModuleService.generateAwsUploadSecureLink(`${queryBody.moduleUuid}/${queryBody.moduleVideo.name}`,"video/mp4","moduleVideoContent")
      return this.uploadVideo(queryBody)
        .then(async (res) => {
          const payload = {
            title: queryBody.title,
            description: queryBody.description,
            order: queryBody.order,
            filename: res.data.filename,
            size: queryBody.moduleVideo.size,
            ResourceUuid:res.data.uuid,
          }
          const response = await this.request().post(`${this.entity}/${queryBody.moduleUuid}`, payload)
          const coverData = {
            moduleVideoUuid: response.data.uuid,
            coverPicture: tempFile
          }
          const uploadVideoCoverSecuredLink = await VideoModuleService.generateAwsUploadSecureLink(`${response.data.uuid}_${queryBody.coverPicture.name}`, "image/png", "moduleVideoCover")
          const uploadResponse = await this.uploadVideoCover(uploadVideoCoverSecuredLink.data.data.url, coverData)
          const finalResponse = {
            videoModuleData: response.data,
            videoModuleCoverData: {filePath: `${response.data.uuid}_${queryBody.coverPicture.name}`}
          }
          return new ResponseWrapper(uploadResponse, finalResponse)
        });

    } catch (error) {
      const message = error.data ? error.data.message : error.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async uploadVideoCover (securedUrl,queryBody) {
    try {
      let binaryFile = new Blob([queryBody.coverPicture])
       const response = await this.request({auth:false}).put(`${securedUrl}`, binaryFile)
      return new ResponseWrapper(response, response?.data)
    } catch (error) {
      const message = error.data ? error.data.message : error.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async uploadVideo (queryBody) {
    try {
      const headers= {
        'Content-Type': 'multipart/form-data'
      }
      const uploadLink = `${API_SERVER_URL}/medias/upload-file?fileName=${queryBody.moduleVideo.name}&contentType=video/mp4&type=moduleVideoContent&moduleUuid=${queryBody.moduleUuid}`
      const formData = new FormData()
      formData.append('moduleVideo',queryBody.moduleVideo)
      const response = await new Http({ auth: true,isApiServerRequest:true }).post(uploadLink,formData,{headers})
      return new ResponseWrapper(response, response?.data)
    } catch (error) {
      const message = error.data ? error.data.message : error.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getAllVideoModule(learningModuleUuid) {
    try {
      const response = await this.request().get(`${this.entity}/${learningModuleUuid}`)
      return new ResponseWrapper(response, response?.data)
    } catch (error) {
      const message = error.data ? error.data.message : error.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async updateVideoModule(videoModuleUuid, queryBody) {
    try {
      delete queryBody.moduleVideo
      delete queryBody.moduleUuid
      const response = await this.request().put(`${this.entity}?moduleLearningVideoUuid=${videoModuleUuid}`, queryBody)
      return new ResponseWrapper(response, response?.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async deleteVideoModule(videoModuleUuid) {
    try {
      const response = await this.request().delete(`${this.entity}?moduleLearningVideoUuid=${videoModuleUuid}`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async streamVideoModule({ videoModuleUuid, learningModuleUuid, token }) {
    try {
      const response = await this.request().get(`${this.entity}/stream/${videoModuleUuid}/${token}/${learningModuleUuid}`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}
