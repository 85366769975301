export default {
  // data
  allLearningModulesList: [],
  teacherModulesRetrievedByTeacherCode: [],
  connectedTeacherModules: [],
  connectedStudentModules: [],
  studentsByLearningModule: [],

  // for data loading (data loaders)
  areAllLearningModulesListLoading: true,
  areTeacherModulesRetrievedByTeacherCodeLoading: false,
  areSendingLearningModuleRegistrationLoading: false,
  areConnectedTeacherModulesLoading: true,
  areConnectedStudentModulesLoading: true,
}