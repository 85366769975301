import state from './learningModuleForumModuleState'
import mutations from './learningModuleForumModuleMutations'
import getters from './learningModuleForumModuleGetters'
import actions from './learningModuleForumModuleActions'

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions
}