import { ErrorWrapper, ResponseWrapper } from './util'
import {Http} from '../axios'

export class LivePlanningService {
    /**
       ******************************
       * @API_CALLS
       ******************************
    **/

    static get entity() {
        return 'livePlanning'
    }

    static request(status = { auth: true }) {
        return new Http(status)
    }

    static async createLive({ queryBody }) {
        try {
            const response = await this.request().post(`${this.entity}/`, queryBody)
            return new ResponseWrapper(response, response.data.data)
        } catch (error) {
            const message = error.response.data ? error.response.data.message : error.response.statusText
            throw new ErrorWrapper(error, message)
        }
    }

    static async updateLive({ liveUuid, queryBody }) {
        try {
            const response = await this.request().put(`${this.entity}/${liveUuid}`, queryBody)
            return new ResponseWrapper(response, response.data.data)
        } catch (error) {
            const message = error.response.data ? error.response.data.message : error.response.statusText
            throw new ErrorWrapper(error, message)
        }
    }

    static async getLiveByTeacherCode({ teacherCode }) {
        try {
            const response = await this.request().get(`${this.entity}/teacher/${teacherCode}`)
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            const message = error.data ? error.data.message : error.statusText
            throw new ErrorWrapper(error, message)
        }
    }

    static async getLiveByStudent({ studenProfileUuid }) {
        try {
            const response = await this.request().get(`${this.entity}/student/${studenProfileUuid}`)
            
            return new ResponseWrapper(response, response.data)
        } catch (error) {
            const message = error.response.data ? error.response.data.message : error.response.statusText
            throw new ErrorWrapper(error, message)
        }
    }

    static async deleteLive({ liveUuid }) {
        try {
            const response = await this.request().delete(`${this.entity}/${liveUuid}`)
            return new ResponseWrapper(response, response.data.data)
        } catch (error) {
            const message = error.response.data ? error.response.data.message : error.response.statusText
            throw new ErrorWrapper(error, message)
        }
    }


}