
/**
 * Check access permission to auth routes
 */
export function checkAccessMiddleware(to, from, next) {

  const currentUser = JSON.parse(localStorage.getItem('currentUser'))
  const isAuthRoute = to.matched.some(item => item.meta.isAuth);
  const hasRight = to.matched.some(item => {
    return (item.meta.neededRole)?.includes(currentUser?.role)
  });

  if (isAuthRoute && hasRight) return next()

  if (isAuthRoute && !hasRight) {
    if(currentUser){
      return next({ name: 'forbidden' })
    } else {
      return next({ name: 'home' })
    }
  }

  if (isAuthRoute) return next({ name: 'login' })

  next()
}

