import { ErrorWrapper, ResponseWrapper } from './util'
import { Http } from '../axios'

export class AdminService {
  /**
     ******************************
     * @API_CALLS
     ******************************
  **/

  static get profileEntity() {
    return 'profile'
  }
  static get adminEntity() {
    return 'admin'
  }

  static request(status = { auth: true }) {
    return new Http(status)
  }

  static async getAllTeachersProfiles(offset=0,size=50) {
    try {
      const response = await this.request().get(`profiles/teachers?offset=${offset}&size=${size}`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getTeacherModules(teacherUUid,offset=0,size=30) {
    try {
      const response = await this.request().get(`teacher/${(teacherUUid)}/modules?offset=${offset}&size=${size}`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getModulesRegisteredStudents(moduleUUid,offset=0,size=30) {
    try {
      const response = await this.request().get(`module-users?moduleUuid=${(moduleUUid)}&offset=${offset}&size=${size}`)
      //const response = await this.request().get(`modules/${(moduleUUid)}/students`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getAllStudentsProfiles(offset=0,size=50) {
    try {
      const response = await this.request().get(`profiles/students?offset=${offset}&size=${size}`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getAllAdminsProfiles(offset=0,size=50) {
    try {
      const response = await this.request().get(`profiles/admins?offset=${offset}&size=${size}`)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async getAllModuleDeletionRequest() {

    try {
      const response = await this.request().get(`/module-deletion-request/all`)
      return new ResponseWrapper(response, response?.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async updateAdminProfile({ queryBody }) {
    try {
      const response = await this.request().put(`${this.profileEntity}/admin`, queryBody)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async validateTeacherAccount({ queryBody }) {
    try {
      const response = await this.request().post('/admin/validate-teacher-account', queryBody)
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static  async validateModuleDelationRequest({queryBody}){
    try{
      const response = await this.request().post('/module-deletion-request/validate',queryBody);
      return new ResponseWrapper(response, response.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async toggleUserAccountStatus({queryBody, userId}) {
    try {
      const response = await this.request().put(`${this.adminEntity}/account/${userId}/toggle/global-access`, queryBody)
      return new ResponseWrapper(response,response.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }

  static async deleteAccount({userId}) {
    try {
      const response = await this.request().delete(`${this.adminEntity}/account/${userId}`)
      return new ResponseWrapper(response,response.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.message : error.response.statusText
      throw new ErrorWrapper(error, message)
    }
  }
}
