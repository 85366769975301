import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import './mixins'
import './plugins'
import {VUE_CONFIG_DEV_TOOLS,VUE_CONFIG_PRODUCTION_TOOL_TIP,GTAG_ID} from './.env.js'

/*------------------------------------- Bootstrap & Vue-Bootstrap -------------------------------------*/
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue/dist/bootstrap-vue.esm';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

/*------------------------------------- Vee-validate -------------------------------------*/
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { extend } from 'vee-validate';
//import VeeValidate from 'vee-validate';
import { required, email, confirmed, min, regex } from 'vee-validate/dist/rules';
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
//Vue.use(VeeValidate);

// No message specified.
extend('email', email);

// Override the default message.
extend('required', {
  ...required,
  message: 'Le champ "{_field_}" est obligatoire'
})

// Override the default mail error message
extend('email', {
  ...email,
  message: 'L\'adresse mail saisie est invalide.'
})

// Override the default password confirmation error message
extend('confirmed', {
  ...confirmed,
  message: 'Les mots de passe ne correspondent pas'
})

// Override the default password min error message
extend('min', {
  ...min,
  message: 'Le mot doit contenir aumoins 10 caractères'
})

extend('regex', {
  ...regex,
  message: 'Le mot doit contenir aumoins 1 chiffre, 1 lettre , 1 majuscule , 1 miniscule, 1 caractère spécial'
})

/*------------------------------------- Meta data -------------------------------------*/
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})

/*------------------------------------- Izi toast -------------------------------------*/
import VueIziToast from 'vue-izitoast';
import 'izitoast/dist/css/iziToast.css';
Vue.use(VueIziToast);

/*------------------------------------- vue tags input -------------------------------------*/
import InputTag from 'vue-input-tag'
Vue.component('input-tag', InputTag)

/*------------------------------------- jw pagination -------------------------------------*/
import JwPagination from 'jw-vue-pagination';
Vue.component('jw-pagination', JwPagination);



/*------------ ASYNC COMPUTED ------------*/

import AsyncComputed from 'vue-async-computed';

Vue.use(AsyncComputed);
import UUID from 'vue-uuid'
Vue.use(UUID);

/** GOOGLE TAG ID ***/
import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { id: GTAG_ID },
  onReady(){
    console.log("successfully load gtag")
  }
},router);



Vue.config.productionTip = VUE_CONFIG_PRODUCTION_TOOL_TIP
Vue.config.devtools = VUE_CONFIG_DEV_TOOLS

window.onerror = function(message, source, line, column, error) {
   console.log(message,source,line,column,error);

}

export const app = new Vue({
  router,
  store,

  render: h => h(App),
})
app.$mount('#app');


