import state from './learningModuleState'
import mutations from './learningModuleMutations'
import getters from './learningModuleGetters'
import actions from './learningModuleActions'

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions
}