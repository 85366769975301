/* eslint-disable no-unused-vars */
import {ForumsService} from "@/services/forums.service";

import {ErrorWrapper} from '../../../services/util'

export default {

    moduleForumSubscribingAction({commit}, learningModuleUuid) {

        ForumsService.moduleForumSubscribing({ModuleUuid: learningModuleUuid});


    },

    moduleForumLoadMoreMessageAction({commit}, {learningModuleUuid, fromDate}) {

        //debugger
        ForumsService.retrieveOldForumsMessages({ModuleUuid: learningModuleUuid, now: fromDate, loadMore: true})


    },

    sendMessageAction({commit}, {learningModuleUuid, Message}) {

        ForumsService.sendMessage({ModuleUuid: learningModuleUuid, Message: Message});

    },

    appendNewMessage({commit}, newMessage) {
        commit('SET_APPEND_NEW_MESSAGE_FORUM_CONVERSATIONS', newMessage.newMessageToBeAppend);
    },

    appendNewOldedMessagesLoaded({commit}, newOldMessagesLoaded) {

        commit('SET_APPEND_NEW_OLD_MESSAGE_LOADED_FORUM_CONVERSATIONS', newOldMessagesLoaded.responseBody);
    },

    successFullSendMessage({commit}, newMessage) {

        commit('SET_SUCCESS_SEND_NEW_MESSAGE', newMessage.newMessageToBeAppend);
    },

    disconnectForumSocketAction() {
        ForumsService.disconnect();
    }


}
